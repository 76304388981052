import React, { Component } from "react";
import { navigate } from "gatsby";
import Cookies from "js-cookie";
import Grid from "@material-ui/core/Grid";
import Fade from "@material-ui/core/Fade";
import Slide from "@material-ui/core/Slide";
import Navbar from "./components/layout/navbar";
import Header from "./components/layout/Header";
import SubHeader from "./components/layout/SubHeader";
import SubHeaderDesc from "./components/layout/SubHeaderDesc";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";

const entityTypes = [
  { type: "C Corp" },
  { type: "S Corp" },
  { type: "LLC" },
  { type: "Sole Proprietor" }
];

const entityState = [
  { statelongName: "Alabama", stateShortName: "AL" },
  { statelongName: "Alaska", stateShortName: "AK" },
  { statelongName: "Arizona", stateShortName: "AZ" },
  { statelongName: "Arkansas", stateShortName: "AR" },
  { statelongName: "California", stateShortName: "CA" },
  { statelongName: "Colorado", stateShortName: "CO" },
  { statelongName: "Connecticut", stateShortName: "CT" },
  { statelongName: "Delaware", stateShortName: "DE" },
  { statelongName: "Florida", stateShortName: "FL" },
  { statelongName: "Georgia", stateShortName: "GA" },
  { statelongName: "Hawaii", stateShortName: "HI" },
  { statelongName: "Idaho", stateShortName: "ID" },
  { statelongName: "Illinois", stateShortName: "IL" },
  { statelongName: "Indiana", stateShortName: "IN" },
  { statelongName: "Iowa", stateShortName: "IA" },
  { statelongName: "Kansas", stateShortName: "KS" },
  { statelongName: "Kentucky", stateShortName: "KY" },
  { statelongName: "Louisiana", stateShortName: "LA" },
  { statelongName: "Maine", stateShortName: "ME" },
  { statelongName: "Maryland", stateShortName: "MD" },
  { statelongName: "Massachusetts", stateShortName: "MA" },
  { statelongName: "Michigan", stateShortName: "MI" },
  { statelongName: "Minnesota", stateShortName: "MN" },
  { statelongName: "Mississippi", stateShortName: "MS" },
  { statelongName: "Missouri", stateShortName: "MO" },
  { statelongName: "Montana", stateShortName: "MT" },
  { statelongName: "Nebraska", stateShortName: "NE" },
  { statelongName: "Nevada", stateShortName: "NV" },
  { statelongName: "New Hampshire", stateShortName: "NH" },
  { statelongName: "New Jersey", stateShortName: "NJ" },
  { statelongName: "New Mexico", stateShortName: "NM" },
  { statelongName: "New York", stateShortName: "NY" },
  { statelongName: "North Carolina", stateShortName: "NC" },
  { statelongName: "North Dakota", stateShortName: "ND" },
  { statelongName: "Ohio", stateShortName: "OH" },
  { statelongName: "Oklahoma", stateShortName: "OK" },
  { statelongName: "Oregon", stateShortName: "OR" },
  { statelongName: "Pennsylvania", stateShortName: "PA" },
  { statelongName: "Rhode Island", stateShortName: "RI" },
  { statelongName: "South Carolina", stateShortName: "SC" },
  { statelongName: "South Dakota", stateShortName: "SD" },
  { statelongName: "Tennessee", stateShortName: "TN" },
  { statelongName: "Texas", stateShortName: "TX" },
  { statelongName: "Utah", stateShortName: "UT" },
  { statelongName: "Vermont", stateShortName: "VT" },
  { statelongName: "Virginia", stateShortName: "VA" },
  { statelongName: "Washington", stateShortName: "WA" },
  { statelongName: "West Virginia", stateShortName: "WV" },
  { statelongName: "Wisconsin", stateShortName: "WI" },
  { statelongName: "Wyoming", stateShortName: "WY" }
];

class entityInfo extends Component {
  state = {
    disableContinue: true,
    entityName: "",
    entityType: "",
    membersNumber: "",
    entityState: "",
    businessCategory: ""
  };

  componentDidMount() {
    this.getStartStore();
  }

  getStartStore() {
    this.setState(
      {
        entityName: Cookies.get("entityName"),
        entityType: Cookies.get("entityType"),
        membersNumber: Cookies.get("membersNumber"),
        entityState: Cookies.get("entityState"),
        businessCategory: Cookies.get("businessCategory")
      },
      this.checkContinue
    );
  }

  checkContinue() {
    if (
      this.state.entityName !== undefined &&
      this.state.entityName !== "" &&
      this.state.entityType !== undefined &&
      this.state.entityType !== "" &&
      this.state.membersNumber !== undefined &&
      this.state.membersNumber !== "" &&
      this.state.entityState !== undefined &&
      this.state.entityState !== "" &&
      this.state.businessCategory !== undefined &&
      this.state.businessCategory !== ""
    ) {
      this.setState({ disableContinue: false });
    } else {
      this.setState({ disableContinue: true });
    }
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
    this.checkContinue();
  };

  onEntityChange = (event, values) => {
    this.setState({ entityType: values["type"] });
    this.checkContinue();
  };

  onStateChange = (event, values) => {
    this.setState({ entityState: values["stateShortName"] });
    this.checkContinue();
  };

  continue = e => {
    Cookies.set("entityName", this.state.entityName);
    Cookies.set("entityType", this.state.entityType);
    Cookies.set("membersNumber", this.state.membersNumber);
    Cookies.set("entityState", this.state.entityState);
    Cookies.set("businessCategory", this.state.businessCategory);
    navigate("/get-ein/checkout");
  };

  render() {
    return (
      <div>
        <Navbar />
        <div style={{ margin: 20 }}>
          <Fade in={true} timeout={1200}>
            <Slide
              direction="up"
              in={true}
              mountOnEnter
              unmountOnExit
              timeout={500}
            >
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
              >
                <Grid item xs={12}>
                  <Header>Entity Filing Information</Header>
                  <br />
                  <br />
                  <Grid spacing={2}>
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justify="center"
                      alignItems="flex-start"
                    >
                      <Grid item md={6} style={{ paddingBottom: 0 }}>
                        <SubHeader>Entity Name</SubHeader>
                        <SubHeaderDesc>
                          Make sure to use the exact name matching your articles
                          of incorporation. Make sure to also include the entity
                          type associated with the name.
                        </SubHeaderDesc>
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          id="entityName"
                          value={this.state.entityName}
                          label="Entity Name"
                          type="text"
                          name="Entity Name"
                          variant="outlined"
                          onChange={e => this.handleChange(e)}
                          style={{ width: "300px" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                  <br />
                  <br />
                  <Grid spacing={2}>
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justify="center"
                      alignItems="flex-start"
                    >
                      <Grid item md={6} style={{ paddingBottom: 0 }}>
                        <SubHeader>Type of Entity</SubHeader>
                        <SubHeaderDesc>
                          Please specify what type of entity you are filing as.
                          Please make sure this mateches what is listed on your
                          artices of incorporation.
                        </SubHeaderDesc>
                      </Grid>
                      <Grid item md={6}>
                        <Autocomplete
                          id="combo-box-demo"
                          options={entityTypes}
                          getOptionLabel={option => option.type}
                          style={{ width: 300 }}
                          onChange={this.onEntityChange}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Entity Type"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                  <br />
                  <br />
                  <Grid spacing={2}>
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justify="center"
                      alignItems="flex-start"
                    >
                      <Grid item md={6} style={{ paddingBottom: 0 }}>
                        <SubHeader>State Filed In</SubHeader>
                        <SubHeaderDesc>
                          Please fill out what state your enity was formed, make
                          sure this matches what is listed on your articles of
                          incorporation.
                        </SubHeaderDesc>
                      </Grid>
                      <Grid item md={6}>
                        <Autocomplete
                          id="combo-box-demo2"
                          options={entityState}
                          getOptionLabel={option => option.statelongName}
                          style={{ width: 300 }}
                          onChange={this.onStateChange}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Entity State"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                  <br />
                  <br />
                  <Grid spacing={2}>
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justify="center"
                      alignItems="flex-start"
                    >
                      <Grid item md={6} style={{ paddingBottom: 0 }}>
                        <SubHeader>
                          How many members are in your entity?
                        </SubHeader>
                        <SubHeaderDesc>
                          If you are filing as a sole proprietor or single
                          member llc please specify 1 for number of members.
                        </SubHeaderDesc>
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          id="membersNumber"
                          value={this.state.membersNumber}
                          label="Number of Members"
                          type="number"
                          name="Number of Members"
                          variant="outlined"
                          onChange={e => this.handleChange(e)}
                          style={{ width: "300px" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                  <br />
                  <br />
                  <Grid spacing={2}>
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justify="center"
                      alignItems="flex-start"
                    >
                      <Grid item md={6} style={{ paddingBottom: 0 }}>
                        <SubHeader>Descibe Your Business</SubHeader>
                        <SubHeaderDesc>
                          Please describe in less than 15 characters what your
                          business does.
                        </SubHeaderDesc>
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          id="businessCategory"
                          value={this.state.businessCategory}
                          label="Business Category"
                          type="text"
                          name="Business Categorye"
                          variant="outlined"
                          onChange={e => this.handleChange(e)}
                          style={{ width: "300px" }}
                        />
                        <br />
                        <br />
                        <br />
                        <br />
                        <Button
                          disabled={this.state.disableContinue}
                          variant="contained"
                          color="primary"
                          size="large"
                          buttonstyle={{ borderRadius: 50 }}
                          style={{
                            borderRadius: 50,
                            textTransform: "none",
                            width: 300
                          }}
                          onClick={this.continue}
                        >
                          Save and Continue
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Slide>
          </Fade>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default entityInfo;
